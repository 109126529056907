const ExploreDefi = () => {
	return (
		<section
			className="w-full relative py-20 sm:py-[120px] overflow-clip bg-white"
			id="TokenPortal"
		>
			<div className="container mx-auto">
				<div>
					<div className="h-full fade-in-up">
						<div className="text-center">
							{/* <p className="support_text_medium text-gray-800 mb-4">
								{" "}
								FEATURES{" "}
							</p> */}
							<h2 className="heading2_semibold text-gray-800 mb-[70px] lg:text-[60px]">
								{" "}
								Navigate DeFi, Confidently{" "}
							</h2>
						</div>
					</div>
				</div>
				<div>
					<div className="h-full relative">
						<div className="bg-gray-200 rounded-lg pt-6 sm:pt-8 sm:pl-8 relative overflow-clip mb-6 min-h-[586px] sm:min-h-[648px]">
							<div className="px-6 sm:px-0">
								<h6 className="heading6_semibold text-gray-800 mb-2">
									{" "}
									All in one DeFi Suite{" "}
								</h6>
								<p className="paragraph2 text-black/[55%] mb-8 sm:mb-7 sm:whitespace-pre-wrap max-w-[400px]">
									The most unified DeFi experience with the widest
									product suite — Meridian Mint, Meridian Lend, Meridian
									Swap & Meridian Trade.
								</p>
								{/* <ul className="flex gap-4 items-center pb-20 flex-wrap pr-10 sm:pr-0">
									<li className="content-list">
										<img src="/img/feature/icon/1.svg" alt="" />
									</li>
									<li className="content-list">
										<img src="/img/feature/icon/2.svg" alt="" />
									</li>
									<li className="content-list">
										<img src="/img/feature/icon/3.svg" alt="" />
									</li>
									<li className="content-list">
										<img src="/img/feature/icon/4.svg" alt="" />
									</li>
								</ul> */}
							</div>
						</div>
						<div className="flex justify-center gap-4 sm:gap-9 absolute bottom-0 left-[2%] sm:left-0 w-[80%] sm:w-[60%]">
							<img
								src="/img/feature/bottom-side.png"
								className="w-full max-w-[500px] sm:max-w-[1500px]"
								alt=""
							/>
						</div>
						<img
							src="/img/feature/right-side.png"
							alt=""
							className="w-[200px] sm:w-[50%] md:w-[45%] lg:w-[50%] max-w-[450px] absolute right-0 bottom-16 sm:-bottom-12"
						/>
					</div>
				</div>
				<div>
					<div className="h-full fade-in-up">
						<div className="flex items-center flex-col sm:flex-row gap-6">
							<div className="bg-gray-200 rounded-lg sm:pt-8 sm:pl-8 relative overflow-clip basis-0 grow w-full min-h-[400px] sm:min-h-[500px]">
								<img
									src="/img/feature/top-img.png"
									alt=""
									className="absolute top-4 right-0 max-w-[631px] w-full"
								/>
								<div className="absolute bottom-6 left-6 sm:bottom-8 sm:left-8 z-10 pr-6 sm:pr-0">
									<h6 className="heading6_semibold text-gray-800 mb-2">
										Ecosystem Growth
									</h6>
									<p className="paragraph2 text-black/[55%] max-w-[400px]">
										Meridian products are designed to work seamlessly together, providing an integrated DeFi experience across all supported ecosystems.
									</p>
								</div>
							</div>
							<div className="bg-primary-deepBlue rounded-lg sm:pt-8 sm:pl-8 relative overflow-clip basis-0 grow w-full min-h-[400px] sm:min-h-[500px]">
								<img
									src="/img/feature/right-img.png"
									alt=""
									className="absolute top-0 left-0 w-full"
								/>
								<div className="absolute bottom-6 left-6 sm:bottom-8 sm:left-8 z-10 pr-6 sm:pr-0">
									<h6 className="heading6_semibold text-gray-200 mb-2">
										Built for Everyone
									</h6>
									<p className="paragraph2 text-white/[55%]">
										User-friendly and advanced features for beginners
										<br className="hidden sm:block" />
										and professionals in all Meridian products.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ExploreDefi;
