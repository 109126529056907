import React, { useEffect, useState } from "react";
import { angleRight } from "../../../components/Icons";
import { cn } from "../../../utils/cn";

const Invest = () => {
  const [activeTab, setActiveTab] = useState(null);
  const [apiValues, setApiValues] = useState({
    circulating_supply: null,
    mst_price: null,
    total_mst_fees: null,
    unique_users: null,
    total_revenue: null,
  });

  const data = [
    {
      id: "1",
      tabTitle: "Staking Portal",
      stat: { circulating_supply: apiValues.total_mst_fees },
      tabSubtitle: `The complete portal for <br className="sm:inline-block hidden" />Managing your stakes.`,
      ratioText: `Total Fees Paid to MST Stakers.`,
      img: "/img/stake-tokens/1.png",
      subtabs: [
        {
          title: "Yield Structure",
          text: "70% of platform revenue is distributed to Liquidity Providers (LPs), while 30% is paid to MST Stakers",
        },
        {
          title: "DeFi Strategies",
          text: "Meridian caters to both beginner and advanced DeFi users by providing the tools they need to craft effective DeFi strategies.",
        },
        {
          title: "Staking Durations",
          text: "User can opt to lock their MST for longer durations to earn amplified yield.",
        },
      ],
    },
    {
      id: "2",
      tabTitle: "Tokenomics",
      stat: { circulating_supply: apiValues.total_revenue },
      tabSubtitle: `MST Supply, Emissions and Distribution.`,
      ratioText: `Total Fees Paid to LPs & MST Stakers`,
      img: "/img/stake-tokens/1.png",
      subtabs: [
        {
          title: "Yield Structure",
          text: "70% of platform revenue is distributed to Liquidity Providers (LPs), while 30% is paid to MST Stakers",
        },
        {
          title: "DeFi Strategies",
          text: "Meridian caters to both beginner and advanced DeFi users by providing the tools they need to craft effective DeFi strategies.",
        },
        {
          title: "Staking Durations",
          text: "User can opt to lock their MST for longer durations to earn amplified yield.",
        },
      ],
    },
    {
      id: "3",
      tabTitle: "Purchase MST",
      stat: { circulating_supply: apiValues.mst_price },
      tabSubtitle: `All the DEXs MST is Trading on.`,
      ratioText: `Current MST Price`,
      img: "/img/stake-tokens/1.png",
      subtabs: [
        {
          title: "Yield Structure",
          text: "70% of platform revenue is distributed to Liquidity Providers (LPs), while 30% is paid to MST Stakers",
        },
        {
          title: "DeFi Strategies",
          text: "Meridian caters to both beginner and advanced DeFi users by providing the tools they need to craft effective DeFi strategies.",
        },
        {
          title: "Staking Durations",
          text: "User can opt to lock their MST for longer durations to earn amplified yield.",
        },
      ],
    },
    {
      id: "4",
      tabTitle: "Circulating Supply",
      stat: { circulating_supply: apiValues.circulating_supply },
      tabSubtitle: `Current Number of MST <br className="sm:inline-block hidden" /> in Circulation.`,
      ratioText: `Circulating supply is ${apiValues.circulating_supply} / 10,000,000.`,
      img: "/img/stake-tokens/1.png",
      subtabs: [
        {
          title: "Yield Structure",
          text: "70% of platform revenue is distributed to Liquidity Providers (LPs), while 30% is paid to MST Stakers",
        },
        {
          title: "DeFi Strategies",
          text: "Meridian caters to both beginner and advanced DeFi users by providing the tools they need to craft effective DeFi strategies.",
        },
        {
          title: "Staking Durations",
          text: "User can opt to lock their MST for longer durations to earn amplified yield.",
        },
      ],
    },
  ];

  // Fetch API values for circulating supply, MST price, etc.
  useEffect(() => {
    const fetchApiValues = async () => {
      try {
        const response = await fetch(
          "https://omnidex.bmaa3ajd1gjri.eu-west-2.cs.amazonlightsail.com/landing_page"
        );
        const data = await response.json();

        const {
          circulating_supply,
          price: mst_price,
          total_mst_fees,
          total_revenue,
          unique_users,
        } = data;

        const formattedSupply = (circulating_supply / 1e18).toLocaleString(
          "en-US",
          {
            maximumFractionDigits: 0,
          }
        );

        const formattedPrice = Number(mst_price).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 3,
        });

        const formattedMSTFees = Number(total_mst_fees).toLocaleString(
          "en-US",
          {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          }
        );

        const formattedRevenue = Number(total_revenue).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        });

        const formattedUsers = Number(unique_users).toLocaleString("en-US");

        setApiValues({
          circulating_supply: formattedSupply,
          mst_price: formattedPrice,
          total_mst_fees: formattedMSTFees,
          unique_users: formattedUsers,
          total_revenue: formattedRevenue,
        });
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };

    fetchApiValues();
  }, []);

  // Set the active tab after data is initialized and apiValues are populated
  useEffect(() => {
    if (data.length > 0 && apiValues.circulating_supply) {
      setActiveTab(data[0]); // Set to the first item in the data array
    }
  }, [apiValues]); // Depend on apiValues to set the default active tab once data is available

  return (
    <section
      className="w-full relative py-20 sm:py-[120px] overflow-clip bg-white"
      id="Staking"
    >
      <img
        src="/img/background_2.png"
        alt=""
        className="w-full h-full absolute left-0 top-0 block object-cover"
      />
      <div className="container mx-auto relative z-10">
        <div className="max-w-[850px]">
          <div>
            {/* <p className="support_text_medium text-gray-200 mb-4 uppercase">
              INVEST
            </p> */}
            <h2 className="heading2_semibold text-white mb-10 sm:mb-16 lg:text-[60px]">
              Participate in Meridian Success By Staking MST Tokens
            </h2>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-14 lg:grid-cols-12">
          <div className="lg:col-span-4 mb-6 sm:mb-0">
            <div className="relative flex flex-col">
              {data.map((item) => (
                <button
                  className={cn(
                    "flex items-center justify-between w-full relative z-10 cursor-pointer text-left p-4 md:p-6 rounded-lg",
                    {
                      "bg-gray-900": activeTab?.id === item.id,
                    }
                  )}
                  key={item.id}
                  type="button"
                  onClick={() => setActiveTab(item)}
                >
                  <div>
                    <h3 className="heading6_semibold text-[16px] sm:text-[18px] mb-3 text-white">
                      {item.tabTitle}
                    </h3>
                    <p
                      className="paragraph text-gray-400 whitespace-pre-wrap sm:text-[15px]"
                      dangerouslySetInnerHTML={{
                        __html: item.tabSubtitle,
                      }}
                    />
                  </div>
                  {angleRight}
                </button>
              ))}
            </div>
            {activeTab && (
              <div className="p-4 sm:p-6 text-gray-200">
                <h4 className="text-[#B2FFC3] text-[80px] sm:text-[120px] lg:text-[40px] 2xl:text-[80px] leading-[1] mb-3">
                  {activeTab.stat.circulating_supply}
                </h4>
                <div className="paragraph sm:text-[15px] max-w-[320px]">
                  {activeTab.ratioText}
                </div>
              </div>
            )}
          </div>
          <div className="lg:col-span-8">
            {activeTab && (
              <>
                <img
                  src={activeTab.img}
                  alt=""
                  className="w-full mb-10 md:mb-14"
                />
                <SubTabs activeTab={activeTab} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

const SubTabs = ({ activeTab }) => {
  const [activeSubTab, setActiveSubTab] = useState(
    activeTab?.subtabs[0] || null
  );

  useEffect(() => {
    setActiveSubTab(activeTab?.subtabs[0] || null);
  }, [activeTab]);

  return (
    <div className="flex flex-wrap gap-x-4 gap-y-8 justify-between items-center">
      <div className="w-full sm:w-0 flex-grow">
        <div className="flex flex-wrap gap-2 mb-4">
          {activeTab?.subtabs?.map((item, index) => (
            <button
              type="button"
              className={cn(
                "py-2 px-3 text-[15px] rounded-lg text-gray-400 md:min-h-10 hover:bg-[#3A434F] hover:text-white duration-200",
                {
                  "bg-[#3A434F] text-white": activeSubTab?.title === item.title,
                }
              )}
              onClick={() => setActiveSubTab(item)}
              key={index}
            >
              {item.title}
            </button>
          ))}
        </div>
        {activeSubTab && (
          <div className="max-w-[380px] text-white">{activeSubTab.text}</div>
        )}
      </div>
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://docs.meridianfinance.net/earning-with-meridian/mst"
          className="rounded-full px-4 py-2 bg-white text-black text-opacity-[0.88] text-[15px] font-semibold min-h-[42px] inline-flex items-center hover:bg-primary-blue hover:text-white duration-300"
        >
          Learn More
        </a>
      </div>
    </div>
  );
};

export default Invest;
